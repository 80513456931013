import Vue from 'vue';
import BaseModel from './BaseModel';
import CompanyModel from './CompanyModel';

export default class AffiliateModel extends BaseModel {
  init(data) {
    this.id = data.id || 0;
    this.logo_url = data.logo_url || 'https://helix-media.s3.amazonaws.com/logos/helix_dark_logo.png';
    this.light_logo_url = data.light_logo_url || data.logo_url || 'https://helix-media.s3.amazonaws.com/logos/helix_light_logo.png';
    this.name = data.name || '';
    this.full_path_display_name = data.full_path_display_name || this.name;
    this.login_logo_width = data.login_logo_width || null;
    this.email_logo_width = data.email_logo_width || null;
    this.email_logo_height = data.email_logo_height || null;
    this.color = data.color || '';
    this.subdomain = data.subdomain || '';
    this.ancestor_data = (data.ancestor_data || []).map(p => new AffiliateModel(p));

    // companies + children from affiliate filters
    this.companies = (data.companies || []).map(c => new CompanyModel(c));
    this.childAffiliates = (data.children || []).map(a => new AffiliateModel(a));
    this.root_name = data.root_name || (this.ancestor_data.length > 0 ? this.ancestor_data[0].name : this.name);

    this.managers_can_batch_verify_data = data.managers_can_batch_verify_data || false;
    this.managers_can_administer_companies = data.managers_can_administer_companies || false;

    this.manager_permissions = data.manager_permissions || [];
  }

  get loginLogoStyles() {
    if (this.login_logo_width) {
      return { 'max-width': `${this.login_logo_width}px !important` };
    }
    return {};
  }

  get display_label() {
    return this.name;
  }

  get fullAncestryPath() {
    if (this.ancestor_data && this.ancestor_data.length) {
      return `${this.ancestor_data.map(a => a.name).join(' > ')} > ${this.name}`;
    }
    return this.name;
  }

  get href() {
    const brandUrl = Vue.prototype.$globalEnv.webBrandUrl || '';
    if (brandUrl) {
      return `${brandUrl.replace('*', this.subdomain)}/affiliate/`;
    }
    return `https://${this.subdomain}.helixintel.com/affiliate/`;
  }

  get registerLink() {
    const brandUrl = Vue.prototype.$globalEnv.webBrandUrl || '';
    if (brandUrl) {
      return `${brandUrl.replace('*', this.subdomain)}/account/claim/`;
    }
    return `https://${this.subdomain}.helixintel.com/account/claim/`;
  }
}
