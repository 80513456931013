import BaseAPIClient from './base/BaseAPIClient';
import ListResponseModel from '../models/ListResponseModel';
import ReservationModel from '../models/ReservationModel';

export default class Reservation extends BaseAPIClient {
  getReservationRequests({ search = '', ordering = 'name', offset = 0, limit = 50 }) {
    const params = {
      search,
      ordering,
      offset,
      limit,
    };

    return this._get('reservations/requests/', params).then(r => new ListResponseModel(r, ReservationModel));
  }
}
