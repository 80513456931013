<template>
  <div
    v-if="$globals.showSiteLoader"
    id="app"
    class="full-page-loader"
  >
    <Loader />
  </div>

  <div
    v-else
    id="app"
  >
    <component :is="layout">
      <b-alert
        ref="successAlert"
        :show="successDismissCountDown"
        variant="success"
        dismissible
        fade
        @dismissed="dismissAlert()"
      >
        <span v-html="$globals.globalSuccessAlertText" />
      </b-alert>

      <b-alert
        ref="errorAlert"
        :show="errorDismissCountDown"
        variant="danger"
        dismissible
        fade
        @dismissed="dismissAlert()"
      >
        <span v-html="$globals.globalErrorAlertText" />
      </b-alert>
      <router-view />
      <session-modal ref="sessionModal" />

      <accept-terms-modal
        ref="termsModal"
        :on-accept="termsAccepted"
        :on-reject="termsRejected"
      />

      <affiliate-verification-modal
        ref="affiliateVerificationModal"
      />

      <affiliate-batch-data-verification-modal
        ref="affiliateBatchDataVerificationModal"
        :on-verified="checkShowAffiliateBatchVerifyNotification"
      />

      <setup-mfa-token-modal
        v-if="$globals.user()"
        ref="setupMfaTokenModal"
        body="In the future it will be required that owner accounts have two factor authentication enabled. Please set this up now."
      />

      <CoolLightBox
        v-if="$globals.lightboxImages"
        :items="$globals.lightboxImages.map(i => i.url)"
        :index="$globals.lightboxIndex"
        @close="$globals.lightboxIndex = null"
      />

      <task-timer />
    </component>
  </div>
</template>

<script>
import CachedDataHelper from '@/cache/CachedDataHelper';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';

import UserClient from '@/api/UserClient';
import NotificationClient from '@/api/NotificationClient';
import VendorHubClient from '@/api/VendorHubClient';
import CompanyClient from '@/api/CompanyClient';
import RelatedCompaniesClient from '@/api/RelatedCompaniesClient';
import AdminClient from '@/api/AdminClient';
import AffiliateClient from '@/api/AffiliateClient';
import MarketplaceClient from '@/api/MarketplaceClient';
import InfoClient from '@/api/InfoClient';
import WeatherClient from '@/api/WeatherClient';
import SuggestionClient from '@/api/SuggestionClient';
import WorkOrderRequestClient from '@/api/WorkOrderRequestClient';
import PMScheduleClient from '@/api/PMScheduleClient';
import ReservationClient from '@/api/ReservationClient';
import routes from '@/constants/routes';
import { defineAsyncComponent } from 'vue';
import { convertHexToHSL, colorChange } from './util.js';

const ALERT_TIMEOUT = 5;

export default {
  name: 'App',
  components: {
    AffiliateBatchDataVerificationModal: defineAsyncComponent(() => import('@/components/modals/AffiliateBatchDataVerificationModal.vue')),
    AcceptTermsModal: defineAsyncComponent(() => import('@/components/modals/AcceptTermsModal.vue')),
    AffiliateVerificationModal: defineAsyncComponent(() => import('@/components/modals/AffiliateVerificationModal.vue')),
    CoolLightBox: defineAsyncComponent(() => import('vue-cool-lightbox')),
    Loader: defineAsyncComponent(() => import('@/components/Loader.vue')),
    SessionModal: defineAsyncComponent(() => import('@/components/modals/SessionModal.vue')),
    SetupMfaTokenModal: defineAsyncComponent(() => import('@/components/modals/SetupMfaTokenModal.vue')),
    TaskTimer: () => import('@/components/TaskTimer.vue'),
  },
  data() {
    return {
      successDismissCountDown: 0,
      errorDismissCountDown: 0,
      affiliateCompanyNeedVerificationCount: 0,

      initialStyleTagValue: '',

      appcuesUrl: 'https://fast.appcues.com/106194.js',
      fontAwesomeKitUrl: 'https://kit.fontawesome.com/a8f97f61dd.js',
      zendeskUrl: 'https://static.zdassets.com/ekr/snippet.js?key=94eb0997-a41b-4d0d-b48b-fe47253c2529',
    };
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || 'default-layout');
    },
    isViewingAffiliatePortal() {
      return this.$route.path.startsWith('/affiliate');
    },
    isViewingOwnerPortal() {
      return this.$route.path.startsWith('/owner') || this.$route.path.startsWith('/admin');
    },
  },
  watch: {
    $route() {
      // ensure user has previously accepted TOS
      this.checkTerms();
      this.checkShowAffiliateBatchVerifyNotification();
      this.checkMfa();
    },
    '$globals.urlAffiliate': function () {
      // regular people have a url affilate
      this.setAffiliateStyles(this.$globals.urlAffiliate);
    },
    '$globals.selectedAffiliate': function () {
      // admin user can select affiliate to override the url
      this.setAffiliateStyles(this.$globals.selectedAffiliate);
    },
    // watch the global alert triggers to show the alert here
    '$globals.globalSuccessAlertText': function (val) {
      if (val) {
        this.successDismissCountDown = this.$globals.globalAlertDelay || ALERT_TIMEOUT;

        // hack to add a "open" class when alert appears to have it animate down nicely
        setTimeout(() => {
          if (this.$refs.successAlert?.$el?.classList) {
            this.$refs.successAlert.$el.classList.add('open');
          }
        }, 300);
      } else {
        this.successDismissCountDown = 0;
      }
    },
    '$globals.globalErrorAlertText': function (val) {
      if (val) {
        this.errorDismissCountDown = this.$globals.globalAlertDelay || ALERT_TIMEOUT;

        // hack to add a "open" class when alert appears to have it animate down nicely
        setTimeout(() => {
          if (this.$refs.errorAlert?.$el?.classList) {
            this.$refs.errorAlert.$el.classList.add('open');
          }
        }, 300);
      } else {
        this.errorDismissCountDown = 0;
      }
    },

    // when we close the alert here, update the global
    successDismissCountDown(val) {
      if (val <= 0) {
        this.$globals.clearGlobalAlert = false;
      }
    },
    errorDismissCountDown(val) {
      if (val <= 0) {
        this.$globals.clearGlobalAlert = true;
      }
    },
    affiliateCompanyNeedVerificationCount(val) {
      if (val <= 0) {
        this.$globals.setTopAlert();
      } else {
        this.$globals.setTopAlert('You have data that needs to be verified.', 'Click here', this.openBatchAffiliateVerificationModal);
      }
    },
  },
  created() {
    window.addEventListener('focus', this.windowFocus);
  },
  destroyed() {
    window.removeEventListener('focus', this.windowFocus);
  },
  mounted() {
    this.initializeHttpClients();

    this.$globals.cachedData = new CachedDataHelper();
    this.$globals.sessionModal = () => this.$refs.sessionModal;

    this.$globals.adminClient.getGlobalSettings().then((res) => {
      this.$globals.globalSettings = {
        show_self_signup: (res.items || []).filter(r => r.boolean_value && r.identifier === 'self_registration').length > 0,
        require_confirm_email: (res.items || []).filter(r => r.boolean_value && r.identifier === 'require_email_verification').length > 0,
      };
    }).catch(() => {
      this.$globals.globalSettings = {
        show_self_signup: false,
        require_confirm_email: true,
      };
    });

    this.addMobileAuthHooks();

    if (this.$globals.accessToken) {
      // we may have to prevent the tracking until they verify equipment or accept terms
      // so reset it until we can check that
      this.$globals.removeTracking();
      this.authorizeUser();
    } else if (!this.$globals.isInFlutterApp()) {
      this.doneLoading();
      this.failedToAuth();
    }

    this.$nextTick(() => {
      // Do this immediately after load to avoid missing icons
      this.$loadScript(this.fontAwesomeKitUrl);
      setTimeout(() => {
        // Do this after a delay to avoid blocking the page load
        this.$loadScript(this.appcuesUrl);
        // Couldn't use vue-plugin-load-script directly since ZenDesk inspects the DOM for the src and id of the element...
        this.addZendeskElement();
      }, 2500);
    });
  },
  methods: {
    initializeHttpClients() {
      this.$globals.userClient = new UserClient(this.$http);
      this.$globals.notificationClient = new NotificationClient(this.$http);
      this.$globals.companyClient = new CompanyClient(this.$http);
      this.$globals.relatedCompaniesClient = new RelatedCompaniesClient(this.$http);
      this.$globals.adminClient = new AdminClient(this.$http);
      this.$globals.affiliateClient = new AffiliateClient(this.$http);
      this.$globals.marketplaceClient = new MarketplaceClient(this.$http);
      this.$globals.infoClient = new InfoClient(this.$http);
      this.$globals.weatherClient = new WeatherClient(this.$http);
      this.$globals.suggestionClient = new SuggestionClient(this.$http);
      this.$globals.vendorHubClient = new VendorHubClient(this.$http);
      this.$globals.workOrderRequestClient = new WorkOrderRequestClient(this.$http);
      this.$globals.pmScheduleClient = new PMScheduleClient(this.$http);
      this.$globals.reservationClient = new ReservationClient(this.$http);
    },
    addZendeskElement() {
      const el = document.createElement('script');
      el.type = 'text/javascript';
      el.async = true;
      el.src = this.zendeskUrl;
      el.id = 'ze-snippet';
      document.head.appendChild(el);
    },
    checkShowAffiliateBatchVerifyNotification() {
      this.$nextTick(() => {
        if (!this.isViewingAffiliatePortal || !this.$globals.user()?.canViewBatchAffiliateDataVerification) {
          this.affiliateCompanyNeedVerificationCount = 0;
          return;
        }

        this.$globals.affiliateClient.getAffiliateCompanies(this.$globals.selectedAffiliate.id, {
          requiresAffiliateDataVerification: true,
          limit: 0,
        }).then((response) => {
          this.affiliateCompanyNeedVerificationCount = response.count;
        }).catch(() => {
          this.affiliateCompanyNeedVerificationCount = 0;
        });
      });
    },
    checkTerms() {
      this.$nextTick(() => {
        const viewingContact = this.$route.name === 'contact'; // let user contact us without verification modals
        const mustVerifyData = !this.$globals.ownerInShadowMode() && (!viewingContact && !this.isViewingAffiliatePortal && !this.isViewingOwnerPortal && this.$globals.company()?.requires_affiliate_data_verification);
        const mustAcceptTOS = !viewingContact && this.$globals.currentUser?.require_terms_acceptance;

        if (mustVerifyData) {
          this.$refs.affiliateVerificationModal?.show();
        } else if (mustAcceptTOS) {
          this.$refs.termsModal?.show();
        }
      });
    },
    async termsAccepted() {
      this.$refs.termsModal.hide();
    },
    termsRejected() {
      return this.$router.push({ name: 'logout' });
    },
    checkMfa() {
      this.$nextTick(() => {
        const mustEnableMfa = this.$globals.isFlagEnabled('login_check_mfa') && this.$globals.user() && this.$globals.user().isOwner && !this.$globals.user().mfa_enabled;

        if (mustEnableMfa) {
          this.$refs.setupMfaTokenModal.show();
        }
      });
    },
    dismissAlert() {
      this.successDismissCountDown = 0;
      this.$globals.globalSuccessAlertText = '';
      this.errorDismissCountDown = 0;
      this.$globals.globalErrorAlertText = '';
    },
    setAffiliateStyles(affiliate) {
      const styleTag = document.getElementById('main-style-variables');

      if (!this.initialStyleTagValue) {
        this.initialStyleTagValue = styleTag.textContent;
      } else {
        styleTag.textContent = this.initialStyleTagValue;
        this.$globals.selectedAffiliateColors = null;
      }

      if (affiliate && affiliate.color) {
        const HSL = convertHexToHSL(`#${affiliate.color}`);
        styleTag.textContent = styleTag.textContent.replace('--color:207,63%;', `--color:${HSL.h},${HSL.s}%;`).replace('--l:35%;', `--l:${HSL.l}%;`);

        const darkAffiliateColor = colorChange(affiliate.color, -10);
        const lightAffiliateColor = colorChange(affiliate.color, 10);
        const zebraColors = Array.from({ length: 30 }, (_, i) => ((i % 2 === 0) ? `#${darkAffiliateColor}` : `#${lightAffiliateColor}`));

        this.$globals.selectedAffiliateColors = zebraColors;
      }
    },
    openBatchAffiliateVerificationModal() {
      if (this.affiliateCompanyNeedVerificationCount > 0) {
        this.$refs.affiliateBatchDataVerificationModal.show(this.affiliateCompanyNeedVerificationCount);
      }
    },
    windowFocus() {
      // also trigger a window resize event
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 300);
    },
    async failedToAuth() {
      // need to do this after we load the user/shadow
      this.$globals.checkUrlAffiliate();

      this.$globals.clearUser();
      await this.$globals.refreshFeatureFlags();
      const currentPath = window.location.pathname;
      const pathConstants = routes;

      // check if current route allows anonymous view

      // TODO: Come up with a better check for routes with params
      const isActivating = currentPath.indexOf('/account/claim') !== -1 || currentPath.indexOf('/reset/') !== -1 || currentPath.indexOf('/verify/') !== -1 || currentPath.indexOf('/invite/') !== -1 || currentPath.indexOf('/confirm/') !== -1 || currentPath.indexOf('onboarding/complete') !== -1 || currentPath.indexOf('users/plugin') !== -1;

      if (!isActivating && pathConstants.filter(p => p.path === currentPath && p.allowAnonymous).length === 0) {
        return this.$router.push({ name: 'login', params: { destination: window.location.href } });
      }
    },
    doneLoading() {
      setTimeout(() => {
        this.$globals.AppLoader = false;
        this.$globals.showSiteLoader = false;
        this.checkTerms();
        this.checkShowAffiliateBatchVerifyNotification();
        this.checkMfa();
      }, 300);
    },
    authorizeUser() {
      return this.$globals.userClient.getRemoteUser().then(async (user) => {
        if (user) {
          this.$globals.setUser(user);
          this.$globals.activateTracking();
          this.$globals.setRefreshToken(true);
          await this.$globals.loadShadows();

          const queryParams = Object.fromEntries(new URLSearchParams(document.location.search));

          let companyId = queryParams.company_id || this.$globals.JUST_JOINED_COMPANY || this.$globals.companyId;

          if (companyId && !user.companies.find(x => x.id === companyId)) {
            // check for an invalid companyId based on the users current list of companies
            companyId = null;
          }

          if (!companyId && user.currentCompany) {
            companyId = user.currentCompany.id;
          }

          this.$globals.JUST_JOINED_COMPANY = '';
          this.$globals.saveCookie();

          // look for shadowUser and shadowCompany
          const shadowUser = this.$globals.shadowUser;
          const shadowCompany = this.$globals.shadowCompany;

          const lastUsedDashboard = this.$globals.selectedDashboard;

          if (shadowUser) {
            await this.$globals.loginAsUser(shadowUser);
          } else if (shadowCompany) {
            await this.$globals.setCompanyId(shadowCompany.id, shadowCompany);
          } else if (companyId) {
            await this.$globals.setCompanyId(companyId);
          } else {
            // make sure users that are only affiliate managers get the feature flags as well
            await this.$globals.refreshFeatureFlags();
          }

          if (!shadowUser && !shadowCompany && lastUsedDashboard && lastUsedDashboard !== 'eo') {
            this.$globals.setDashboard(lastUsedDashboard);
          }

          this.$globals.getUnreadCounts();
          this.$globals.testRoutePermission();

          // need to do this after we load the user/shadow
          this.$globals.checkUrlAffiliate();
        } else {
          this.failedToAuth();
        }
      }).catch(this.failedToAuth).finally(this.doneLoading);
    },
    addMobileAuthHooks() {
      // wait for the app to call something to tell us if the user needs to login or use the existing credentials
      // app to launch going to mobile-login
      // mobile app hooks
      window.HelixIntelMobileSetPushToken = (token, device_id, platform) => {
        this.$globals.setPushToken(token, device_id, platform);
      };

      window.HelixIntelMobileSetKeyboardState = (open, height) => {
        this.$globals.setMobileKeyboardState(open, height);
      };

      window.HelixIntelMobileLogin = (access, refresh) => {
        if (access && refresh) {
          this.$globals.setTokens({
            access,
            refresh,
          });
          this.authorizeUser().then(() => {
            // logged in, if we are looking at a login page go to dashboard instead
            if (this.$globals.user() && this.$route.name.includes('login')) {
              this.$router.push({
                name: this.$globals.user().homeRouteName,
              });
            } else {
              this.doneLoading();
            }
          });
        } else {
          // page finished mounting since the app called this function, so we can hide the loader
          this.doneLoading();
          if (window.location.pathname.indexOf('/users/sso/login/') === -1) {
            // failed and not doing sso, go to login
            this.failedToAuth();
            this.$router.replace({ name: 'login' });
          }
        }
      };
    },
  },
};

</script>

<style lang="scss">
@import "@/assets/styles/styles.scss";

.alert {
  z-index: 9999;
}
</style>
