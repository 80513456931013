import BaseModel from './BaseModel';
import FileModel from './FileModel';
import CompanyEquipmentModel from './CompanyEquipmentModel';
import PartModel from './PartModel';
import TagModel from './TagModel';
import CompanyModel from './CompanyModel';
import CustomFieldModel from './CustomFieldModel';

class CompanyLocationModel extends BaseModel {
  init(data) {
    this.id = data.id || 0;
    this.name = data.name || '';

    this.image = data.image ? new FileModel(data.image) : null; // cover image
    this.images = (data.images || []).map(f => new FileModel(f));

    this.description = data.description || '';
    this.child_location_count = data.child_location_count || 0;

    // active only for model display
    this.tags = (data.tags || []).map(t => new TagModel(t)).filter(t => t.active);
    this.custom_fields = (data.custom_field_values || []).map(t => new CustomFieldModel(t)).filter(t => t.active);

    this.street_address = data.street_address || '';
    this.latitude = (data.location && data.location.latitude) || 0;
    this.longitude = (data.location && data.location.longitude) || 0;
    this.street_address_line_2 = data.street_address_line_2 || '';
    this.city = data.city || '';
    this.state = data.state || '';
    this.zip = data.zip || '';
    this.phone = data.phone || '';
    this.phone_ext = data.phone_ext || '';
    this.qr_code = data.qr_code || '';

    this.square_footage = data.square_footage;

    this.hours_of_operation = data.hours_of_operation || '';

    if (this.hours_of_operation === 'null') {
      // bandaid
      this.hours_of_operation = '';
    }

    this.remodel_summary = data.remodel_summary || '';
    this.remodel_year = data.remodel_year || null;
    this.floor_type_1 = data.floor_type_1 || '';
    this.floor_type_2 = data.floor_type_2 || '';
    this.cabinet_type = data.cabinet_type || '';
    this.countertop_type = data.countertop_type || '';
    this.wall_coverings = data.wall_coverings || '';

    this.foundation_type = data.foundation_type || '';
    this.construction_type = data.construction_type || '';
    this.roof_system = data.roof_system || '';

    this.lighting_interior_type = data.lighting_interior_type || '';
    this.lighting_exterior_type = data.lighting_exterior_type || '';

    this.window_material = data.window_material || '';
    this.garage_parking_material = data.garage_parking_material || '';

    this.wiring = data.wiring || '';

    this.water_provider = data.water_provider || '';
    this.electric_provider = data.electric_provider || '';
    this.gas_provider = data.gas_provider || '';
    this.heating_provider = data.heating_provider || '';
    this.sewer_provider = data.sewer_provider || '';
    this.communication_provider = data.communication_provider || '';

    this.parent = data.parent || 0;
    this.ancestor_data = (data.ancestor_data || []).map(p => new CompanyLocationModel(p));

    this.archived = 'archived' in data ? data.archived : false;

    if ('ancestor_archived' in data) {
      this.ancestor_archived = 'ancestor_archived' in data ? data.ancestor_archived : false;
    } else if (this.ancestor_data?.length) {
      this.ancestor_archived = this.ancestor_data.some(l => l.archived);
    }

    this.children = data.children || []; // [{id, name},...]
    this.restricted_user_info = data.restricted_user_info || []; // [{id, name, restricted_location_name...},...]

    this.attachments = (data.attachments || []).map(f => new FileModel(f));

    // get single only
    this.equipments = (data.equipments || []).map(f => new CompanyEquipmentModel(f));
    this.parts = (data.parts || []).map(f => new PartModel(f));

    this.parent_id = data.parent_id || 0;
    if (!this.parent_id) {
      if (this.parent) {
        this.parent_id = this.parent.id;
      } else if (this.ancestor_data?.length) {
        this.parent_id = this.ancestor_data[this.ancestor_data.length - 1].id;
      }
    }

    this.full_path_display_name = data.full_path_display_name || this.fullAncestryPath;
  }

  get archivedOrAncestorArchived() {
    return this.ancestor_archived || this.archived || false;
  }

  get descriptionHtml() {
    return this.getHtmlDisplayValue(this.description);
  }

  get isProperty() {
    return false;
  }

  get href() {
    return `/location/${this.id}`;
  }

  get position() {
    if (!this.latitude && !this.longitude) {
      return null;
    }

    return {
      lat: this.latitude,
      lng: this.longitude,
    };
  }

  get display_label() {
    return this.name;
  }

  get immediateParent() {
    if (this.parent) {
      const parents = this.ancestor_data.filter(a => a.id === this.parent);
      if (parents.length) {
        return parents[0];
      }
    }
    return null;
  }

  get rootParent() {
    if (!this.ancestor_data.length) {
      return null;
    }
    return this.ancestor_data[0];
  }

  isChildOf(id) {
    return this.ancestor_data.filter(a => a.id === id).length > 0;
  }

  get fullAncestryPath() {
    if (this.ancestor_data && this.ancestor_data.length) {
      return `${this.ancestor_data.map(a => a.name).join(' > ')} > ${this.name}`;
    }
    return this.name;
  }

  get singleLineFullAddress() {
    const parts = [];
    if (this.street_address) {
      parts.push(this.street_address);
    }

    const cityStateZip = this.cityStateZipDisplay;
    if (cityStateZip) {
      parts.push(cityStateZip);
    }
    return parts.join(' - ');
  }

  get cityStateZipDisplay() {
    const parts = [];
    if (this.city) {
      parts.push(this.city);
    }
    if (this.state && this.zip) {
      parts.push(`${this.state} ${this.zip}`);
    } else if (this.state) {
      parts.push(this.state);
    } else if (this.zip) {
      parts.push(this.zip);
    }

    return parts.join(', ');
  }
}

// A PropertyLocationModel is a CompanyLocationModel with a null parent.
// It may have 0 or many children
class PropertyLocationModel extends CompanyLocationModel {
  static factory(data) {
    return data.parent ? new CompanyLocationModel(data) : new PropertyLocationModel(data);
  }

  init(data) {
    super.init(data);

    this.category = data.category || {};
    this.category_other = data.category_other || '';

    this.story_count = data.story_count;
    this.building_count = data.building_count;
    this.room_count = data.room_count;
    this.year_built = data.year_built;

    this.roof_installation_year = data.roof_installation_year || '';
    this.garage_parking_installation_year = data.garage_parking_installation_year || '';
    this.window_installation_year = data.window_installation_year || '';

    this.light_ballasts_count = data.light_ballasts_count;
    this.lighting_interior_installation_year = data.lighting_interior_installation_year || '';
    this.lighting_exterior_installation_year = data.lighting_exterior_installation_year || '';

    if (data.has_sprinklers !== null && typeof data.has_sprinklers !== 'undefined') {
      this.has_sprinklers = data.has_sprinklers || false;
    } else {
      this.has_sprinklers = null;
    }

    if (data.has_smoke_alarms !== null && typeof data.has_smoke_alarms !== 'undefined') {
      this.has_smoke_alarms = data.has_smoke_alarms || false;
    } else {
      this.has_smoke_alarms = null;
    }

    if (data.has_security_system !== null && typeof data.has_security_system !== 'undefined') {
      this.has_security_system = data.has_security_system || false;
    } else {
      this.has_security_system = null;
    }

    if (data.wind_hazard !== null && typeof data.wind_hazard !== 'undefined') {
      this.wind_hazard = data.wind_hazard || false;
    } else {
      this.wind_hazard = null;
    }

    if (data.flood_hazard !== null && typeof data.flood_hazard !== 'undefined') {
      this.flood_hazard = data.flood_hazard || false;
    } else {
      this.flood_hazard = null;
    }

    this.approximate_building_value = parseFloat(data.approximate_building_value) || null;
    this.content_value = parseFloat(data.content_value) || null;
    this.rent_value = parseFloat(data.rent_value) || null;
    this.total_insured_value = parseFloat(data.total_insured_value) || null;
    this.building_square_footage = data.building_square_footage;
    this.lot_square_footage = data.lot_square_footage;
    this.office_square_footage = data.office_square_footage;
    this.retail_square_footage = data.retail_square_footage;
    this.rentable_square_footage = data.rentable_square_footage;
    this.common_area_square_footage = data.common_area_square_footage;
    this.garage_parking_square_footage = data.garage_parking_square_footage;
    this.misc_square_footage = data.misc_square_footage;
    this.vacant_land_acres = data.vacant_land_acres;
    this.pool_count = data.pool_count;
  }

  get isProperty() {
    return true;
  }

  get thumbnail_url() {
    return (this.image && this.image.thumbnail_url) || (this.image && this.image.url) || '';
  }

  get placeholderIcon() {
    return ['far', 'building'];
  }

  get grossSqFtExcludingGarage() {
    const fields = ['office_square_footage', 'retail_square_footage', 'rentable_square_footage', 'common_area_square_footage', 'misc_square_footage'];
    const hasAValue = fields.filter(f => !(this[f] === null || this[f] === '')).length > 0;
    if (!hasAValue) {
      return null;
    }
    return fields.map(f => this[f]).reduce((p, a) => p + a, 0);
  }

  // allow input-select integration to bool
  get _has_sprinklers() {
    if (this.has_sprinklers === null) {
      return '';
    }

    return this.has_sprinklers ? 'true' : 'false';
  }

  set _has_sprinklers(v) {
    this.has_sprinklers = v === 'true';
  }

  get _has_smoke_alarms() {
    if (this.has_smoke_alarms === null) {
      return '';
    }

    return this.has_smoke_alarms ? 'true' : 'false';
  }

  set _has_smoke_alarms(v) {
    this.has_smoke_alarms = v === 'true';
  }

  get _has_security_system() {
    if (this.has_security_system === null) {
      return '';
    }

    return this.has_security_system ? 'true' : 'false';
  }

  set _has_security_system(v) {
    this.has_security_system = v === 'true';
  }

  get _wind_hazard() {
    if (this.wind_hazard === null) {
      return '';
    }

    return this.wind_hazard ? 'true' : 'false';
  }

  set _wind_hazard(v) {
    this.wind_hazard = v === 'true';
  }

  get _flood_hazard() {
    if (this.flood_hazard === null) {
      return '';
    }

    return this.flood_hazard ? 'true' : 'false';
  }

  set _flood_hazard(v) {
    this.flood_hazard = v === 'true';
  }

  get categoryDisplay() {
    if (!this.category) return null;
    let display = this.category.full_path_display_name;
    if (this.category.name === 'Other' && this.category_other) {
      display += ` (${this.category_other})`;
    }
    return display;
  }
}

class IncentiveLocationModel extends CompanyLocationModel {
  static noIncentiveMagicString = 'None or no incentive offered';

  init(data) {
    super.init(data);
    this.name = data.name || '';
    this.square_footage = data.square_footage;
    this.property_type = data.property_type || '';
    this.construction_type = data.construction_type || '';
    this.asset_type = data.asset_type || '';
    this.unit_count = data.unit_count;
    this.electric_utility = data.electric_utility || '';
    this.gas_utility = data.gas_utility || '';
    this.water_utility = data.water_utility || '';

    this.incentive_data = data.incentive_data || null;
    this.incentive_data_finalized = data.incentive_data_finalized || false;

    if (data.individually_metered_units !== null && typeof data.individually_metered_units !== 'undefined') {
      this.individually_metered_units = data.individually_metered_units || false;
    } else {
      this.individually_metered_units = null;
    }
  }

  get incentives_cleaned() {
    return (this.incentive_data?.incentives || []).filter(i => i.type !== IncentiveLocationModel.noIncentiveMagicString);
  }
}

class AffiliateLocationModel extends CompanyLocationModel {
  init(data) {
    super.init(data);
    this.company = data.company ? new CompanyModel(data.company) : null;
  }

  get company_name() {
    return (this.company && this.company.name) || '';
  }
}

class AffiliatePropertyLocationModel extends PropertyLocationModel {
  static factory(data) {
    return data.parent ? new AffiliateLocationModel(data) : new AffiliatePropertyLocationModel(data);
  }

  init(data) {
    super.init(data);
    this.company = data.company ? new CompanyModel(data.company) : null;
  }

  get company_name() {
    return (this.company && this.company.name) || '';
  }
}

export {
  PropertyLocationModel,
  AffiliatePropertyLocationModel,
  IncentiveLocationModel,
};
