import BaseModel from './BaseModel';
import UserModel from './UserModel';

export default class ReservationModel extends BaseModel {
  static ReservationRequestStatus = {
    PENDING_REVIEW: 'pending_review',
    CANCELED: 'canceled',
    REVIEWED: 'reviewed',
  };

  init(data) {
    this.id = data.id || 0;
    this.name = data.name || '';
    this.description = data.description || '';
    this.status = data.status || '';
    this.user = data.user ? new UserModel(data.user) : null;
    this.reviewers = (data.reviewers || []).map(a => new UserModel(a));
  }
}
